.hero-container {
    text-align: center;
    padding: 10px 10px;
    background-color: #927878;
   
  }
  
  .hero-container h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero-container p {
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media screen and (max-width: 768px) {
    .hero-container {
      padding: 70px 15px;
    }
  
    .hero-container h1 {
      font-size: 36px;
    }
  
    .hero-container p {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .hero-container {
      padding: 50px 10px;
    }
  
    .hero-container h1 {
      font-size: 28px;
    }
  
    .hero-container p {
      font-size: 16px;
    }
  }
  