.App {
  text-align: center; 
}








