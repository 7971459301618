.pricing-section-container {
    text-align: center;
    padding: 50px 20px;
    background-color:  #927878;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: space-around;
  }
  
  .pricing-card {
    border: 1px solid #ddd;
    padding: 20px;
    width: 30%;
    border-radius: 5px;
  }
  
  .pricing-card h3 {
    margin-bottom: 10px;
  }
  
  .pricing-card button {
    margin-top: 20px;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  