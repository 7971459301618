
.home-container {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); 
}
.bride-groom-names{
  font-size: 10vw;
  text-align: center;
  margin-bottom: 10rem;
  color:  rgb(0, 0, 0) ;
  z-index: 999;
}
@media screen and (min-width: 769px) {
  .bride-groom-names{
    font-size: 4vw;
  }
}
.start-button {
  width: 20vw;  
  height: 20vw; 
  max-width: 150px; 
  max-height: 150px;
  min-width: 100px; 
  min-height: 100px;
  border-radius: 50%;
  border: 3px solid #131010;
  background: transparent;
  font-size: 5vw; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #131010;
  z-index: 999;
}

.start-button span {
  font-size: 4vw; 
}
