.gallery-container {
    background: #927878;
    margin: 0;
    padding: 20px 0; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .gallery-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .gallery-heading h1 {
    font-size: 2.5rem;
  }
  
  .gallery-heading p {
    font-size: 1.2rem;
    color: #ddd;
  }
  
 
  .swiper {
    width: 100%; 
    padding-top: 50px;
    padding-bottom: 80px;
  }
  
  .swiper .swiper-slide-custom {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    -webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0006);
    margin: 0 auto;
  }
  
  /* Responsive Styles */
  
  /* Tablets and smaller desktops */
  @media screen and (max-width: 1200px) {
    .swiper .swiper-slide-custom {
      width: 250px;
      height: 250px;
    }
    .swiper {
        width: 520px;; 
      }
  }
  
  /* Large Phones and Tablets */
  @media screen and (max-width: 900px) {
    .swiper {
      width: 420px;; 
    }
  
    .swiper .swiper-slide-custom {
      width: 200px;
      height: 200px;
    }
  
    .gallery-container {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  
    .gallery-heading h1 {
      font-size: 2rem;
    }
  
    .gallery-heading p {
      font-size: 1rem;
    }
  }
  
  
  @media screen and (max-width: 600px) {
    .swiper {
      width: 320px;
    }
  
    .swiper .swiper-slide-custom {
      width: 150px;
      height: 150px;
    }
  
    .gallery-container {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  
    .gallery-heading h1 {
      font-size: 1.8rem;
    }
  
    .gallery-heading p {
      font-size: 0.9rem;
    }
  }
  