.language-button-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .language-toggle-button {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .language-toggle-button:hover {
    background-color: #555;
  }
  
  .language-dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 45px;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .language-dropdown li {
    padding: 10px 20px;
    cursor: pointer;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .language-dropdown li:hover {
    background-color: #f0f0f0;
  }
  
  .language-dropdown li:focus {
    background-color: #ddd;
  }
  