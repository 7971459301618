.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  position: relative;
}

.upload-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); 
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.3); 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.upload-text {
  font-size: 20px;
  color: #131010;
  margin-bottom: 10rem;
  text-align: center;
  font-family: "Frank Ruhl Libre", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #131010;
  color: white;
  border-radius: 5px;
  margin-bottom: 20px;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.upload-button {
  padding: 10px 20px;
  background-color: #131010;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Frank Ruhl Libre", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.upload-button:hover {
  background-color: #444; 
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress {
  height: 10px;
  background-color: #1f1b1b;
  width: 0;
  transition: width 0.4s ease;
}

.buttons-container {
  display: flex;
  justify-content: space-around; 
  width: 100%;
}
