.business-summary-container {
  text-align: center;
  padding: 20px;
  background-color: #927878;
  
}

.business-summary-container ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
}

.business-summary-container ul li {
  padding: 10px 0;
  font-size: 18px;
}
ul{
  display: flex;
  flex-direction: column;
}


/* Responsive Styles */
@media screen and (max-width: 768px) {
  .business-summary-container {
    padding: 15px;
  }

  .business-summary-container ul li {
    font-size: 16px;
    padding: 8px 0;
  }
  li{
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .business-summary-container {
    padding: 10px;
  }

  .business-summary-container ul li {
    font-size: 14px;
    padding: 6px 0;
  }
  li{
    font-size: 10px;
  }
  
}
